import Vue from 'vue';
import Vuex from 'vuex';
import general from './modules/general';
import article from './modules/article';
import product from './modules/product';
import subcategory from './modules/subcategory';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    general,
    article,
    product,
    subcategory,
  },
});
