<template>
	<section
		class="section section--larger"
		:class="bgGrey && bgGrey === true ? 'has-bg-grey' : ''"
	>
		<div class="container">
			<h2 class="section__title has-text-centered">
				Calitate maximă confirmată internațional
			</h2>
			<div class="columns is-multiline is-centered">
				<div
					class="column is-2-tablet is-1-desktop"
					v-for="certificate in certificates"
					:key="certificate.id"
				>
					<div class="certificate has-text-centered">
						<img
							:src="`${databaseLink}/${certificate.imagine.url}`"
							:alt="certificate.imagine.alternativeText"
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		props: {
			certificates: {
				type: Array,
				require: true,
			},
			bgGrey: {
				type: Boolean,
			},
		},
		computed: {
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.has-bg-grey {
		background-color: lighten($gray, 28%);
	}
</style>
