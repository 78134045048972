<template>
	<div
		:class="
			submitedForm.status === 'submited' ? 'form-success' : 'form-error'
		"
		class="p-3 form-msg has-text-centered"
		style="width: 300px"
	>
		<p v-if="submitedForm.status === 'submited'">
			{{ submitedForm.msg }}
		</p>
		<p v-else>{{ submitedForm.msg }}</p>
	</div>
</template>

<script>
	export default {
		props: {
			submitedForm: Object,
		},
	};
</script>

<style lang="scss" scoped>
	.form-error,
	.form-success {
		box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
			0 2px 4px -1px rgba(0, 0, 0, 0.06);
		background-color: darken($secondary, 15%);
		border-radius: $round-corners;
		position: fixed;
		bottom: 1rem;
		left: 50%;
		transform: translateX(-50%);
		color: $white;
		z-index: 999;
	}
	.form-success {
		background-color: darken($secondary, 15%);
	}
	.form-error {
		background-color: darken($red, 15%);
	}
</style>
