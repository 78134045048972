<template>
	<section
		class="section section__testimonial section--larger"
		v-if="carouselTestimonial && carouselTestimonial.length > 0"
	>
		<div class="container">
			<h2 class="section__title has-text-centered">
				Testimoniale Forever
			</h2>
			<swiper ref="mySwiper" :options="swiperOptions">
				<swiper-slide
					v-for="slide in carouselTestimonial"
					:key="slide.id"
				>
					<div
						class="testimonial is-flex is-flex-direction-column is-align-items-center"
					>
						<div
							class="testimonial__content is-flex is-align-items-center py-6 px-5"
						>
							<div class="icon">
								<span>&#8220;</span>
							</div>
							<p class="pl-4">
								{{ slide.descriere }}
							</p>
						</div>
						<span class="dot mt-2"></span>
						<span class="dot mt-2"></span>
						<div class="testimonial__avatar mt-2">
							<img
								width="150"
								height="150"
								:src="`${databaseLink}/${slide.imagine[0].url}`"
								:alt="slide.titlu"
							/>
						</div>
						<h4 class="testimonial__title mt-2">
							{{ slide.autor }}
						</h4>
					</div>
				</swiper-slide>
				<div class="swiper-pagination" slot="pagination"></div>
			</swiper>
		</div>
	</section>
</template>

<script>
	import { mapState } from 'vuex';
	export default {
		data() {
			return {
				swiperOptions: {
					pagination: {
						el: '.swiper-pagination',
					},
					slidesPerView: 1,
					loop: true,
					spaceBetween: 10,
					autoplay: {
						delay: 4000,
					},
					speed: 400,
				},
			};
		},
		computed: {
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			},
			...mapState('general', ['carouselTestimonial']),
		},
	};
</script>

<style scoped lang="scss">
	.section {
		background-color: lighten($gray, 28%);
	}
	.testimonial {
		&__content {
			background-color: $primary;
			border-radius: $round-corners * 2;
			.icon {
				width: 64px;
				height: 64px;
				span {
					color: $white;
					display: inline-block;
					font-size: 8rem;
					line-height: 8rem;
					height: 64px;
				}
			}
			p {
				color: rgba($white, 0.9);
				font-size: 1.125rem;
				text-align: center;
			}
		}
		.dot {
			background-color: $primary;
			border-radius: 50%;
			display: inline-block;
			&:first-of-type {
				width: 1rem;
				height: 1rem;
			}
			&:last-of-type {
				width: 1.75rem;
				height: 1.75rem;
			}
		}
		&__avatar {
			background-color: $primary;
			border-radius: 50%;
			height: 150px;
			width: 150px;
			img {
				border-radius: inherit;
				width: inherit;
				height: inherit;
			}
		}
		&__title {
			font-size: 1.5rem;
			font-weight: $semibold;
		}
	}
</style>
