<template>
	<ul class="contact-list">
		<li
			:title="
				info.simbol === 'call-outline' ? 'Telefon' : 'Adresa de mail'
			"
			:aria-label="
				info.simbol === 'call-outline'
					? 'Contactează-ne pe telefon'
					: 'Contactează-ne pe adresa de mail'
			"
			class="item is-relative is-flex is-justify-content-flex-end"
			v-for="info in contactInfos"
			:key="info.id"
		>
			<a class="item__content" :href="info.url">{{ info.titlu }}</a>
			<div class="item__icon is-relative">
				<ion-icon :name="info.simbol"></ion-icon>
			</div>
		</li>
		<li
			@click="openModalProductsList"
			v-if="productsList && productsList.length > 0"
			class="item is-relative"
			title="Lista de produse"
			aria-label="Vizualizează lista de produse"
		>
			<span class="products-number">
				{{ productsList.length }}
			</span>
			<div class="item__icon is-relative">
				<ion-icon name="happy-outline"></ion-icon>
			</div>
		</li>
	</ul>
</template>

<script>
	import { mapState } from 'vuex';
	export default {
		data() {
			return {};
		},
		computed: {
			...mapState('general', ['contactInfos']),
			...mapState('product', ['productsList']),
		},
		methods: {
			openModalProductsList() {
				this.$store.commit(
					'product/SET_MODAL_PRODUCTS_LIST_STATUS',
					true
				);
			},
		},
	};
</script>

<style scoped lang="scss">
	.contact-list {
		position: fixed;
		top: 20%;
		right: 0;
		z-index: 4;
		.item {
			width: 50px;
			margin-bottom: 2px;
			&:last-child {
				margin: 0;
			}
			&:hover {
				.item__content {
					right: 50px;
				}
			}
			&__icon {
				background-color: $primary;
				color: $white;
				font-size: 1.5rem;
				line-height: 56px;
				transition: background-color 0.3s ease;
				text-align: center;
				pointer-events: none;
				width: 50px;
				height: 50px;
				z-index: 1;
				&:hover {
					background-color: lighten($primary, 10%);
				}
			}
			&__content {
				background-color: lighten($primary, 10%);
				color: $white;
				font-weight: $medium;
				letter-spacing: 1px;
				line-height: 50px;
				padding: 0 0.5rem;
				position: absolute;
				top: 0;
				right: -194px;
				transition: right 0.3s ease-in-out;
				text-align: right;
				width: 194px;
			}
			.products-number {
				box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
				background-color: $secondary;
				border-radius: 50%;
				color: $white;
				font-size: 0.75rem;
				font-weight: $medium;
				line-height: 25px;
				position: absolute;
				top: 0;
				left: 0;
				transform: translateX(-50%);
				z-index: 3;
				text-align: center;
				width: 25px;
				height: 25px;
			}
		}
	}
</style>
