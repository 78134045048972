<template>
	<div
		id="loading"
		class="is-flex is-justify-content-center is align-items-center"
	>
		<img :src="require('@/assets/ball-triangle.svg')" alt="Se încarcă..." />
	</div>
</template>

<script>
	export default {};
</script>

<style lang="scss" scoped>
	#loading {
		background-color: $white;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 99999;
		img {
			width: 60px;
		}
	}
</style>
