import axios from 'axios';

let productsList = window.localStorage.getItem('products');

const state = {
	product: {},
	currentProduct: null,
	products: [],
	productsList: productsList ? JSON.parse(productsList) : [],
	modalProductsList: false,
	modalWithMultipleProducts: false,
	modalWithSingleProduct: false,
	productInList: false
};

const mutations = {
	SET_PRODUCT(state, payload) {
		state.product = payload;
	},
	SET_PRODUCTS(state, payload) {
		state.products = payload;
	},
	ADD_PRODUCT_TO_LIST(state, product) {
		let found = state.productsList.find((item) => item.id === product.id);
		if (!found) {
			state.productsList.push(product);
			state.productInList = true;
		}


		window.localStorage.setItem(
			'products',
			JSON.stringify(state.productsList)
		);
	},
	REMOVE_PRODUCT_FROM_LIST(state, payload) {
		state.productsList.splice(state.productsList.indexOf(payload.id), 1);
		window.localStorage.setItem(
			'products',
			JSON.stringify(state.productsList)
		);
	},
	CLEAR_LIST(state) {
		state.productsList = [];
		window.localStorage.setItem(
			'products',
			JSON.stringify(state.productsList)
		);
	},
	SET_MODAL_PRODUCTS_LIST_STATUS(state, payload) {
		state.modalProductsList = payload;
	},
	SET_MODAL_COMMAND_WITH_MULTIPLE_PRODUCTS_STATUS(state, payload) {
		state.modalWithMultipleProducts = payload;
	},
	SET_MODAL_COMMAND_WITH_SINGLE_PRODUCT_STATUS(state, payload) {
		state.currentProduct = payload.product;
		state.modalWithSingleProduct = payload.status;
	},
	UPDATE_QUANTITY_PRICE(state, payload) {
		if(payload.type === 'product') {
			state.currentProduct._pret = (parseInt(state.currentProduct._cantitate) * state.currentProduct.pret).toFixed(2);
		} else {
			const currentProduct = state.productsList.filter(
				(prod) => prod.id === payload
			);
			currentProduct[0]._pret = (
				parseInt(currentProduct[0]._cantitate) * currentProduct[0].pret
			).toFixed(2);
			window.localStorage.setItem(
				'products',
				JSON.stringify(state.productsList)
			);

		}
	},
};

const actions = {
	async getProduct({ commit }, id) {
		const response = await axios.get(
			`${process.env.VUE_APP_STRAPI_DATABASE_URL}/products/${id}`
		);
		const product = await response.data;
		const prodObj = {...product, _pret: product.pret};
		commit('SET_PRODUCT', prodObj);
	},
	async getProducts({ commit }) {
		const response = await axios.get(
			`${process.env.VUE_APP_STRAPI_DATABASE_URL}/products`
		);
		const products = await response.data;
		commit('SET_PRODUCTS', products);
	},
};

const getters = {
	isProductInList: (state) => (product) => {
		console.log(product)
		return state.productsList.includes(product);
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
};
