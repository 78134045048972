import axios from 'axios';

const state = {
  subcategory: {},
};

const mutations = {
  SET_SUBCATEGORY(state, payload) {
    state.subcategory = payload;
  },
};

const actions = {
  async getSubcategory({ commit }, name) {
    const response = await axios.get(
      `${process.env.VUE_APP_STRAPI_DATABASE_URL}/subcategories/?nume=${name}`
    );
    const subcategory = await response.data[0];
    commit('SET_SUBCATEGORY', subcategory);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
