<template>
	<div class="dialog" style="background: white">
		<div
			class="dialog__header is-flex is-align-items-center is-justify-content-space-between p-4"
		>
			<h2>Produse</h2>
			<button
				class="btn btn--close"
				@click="closeModal"
				title="Închide dialog"
				aria-label="Închide dialog"
			>
				<ion-icon name="close-outline"></ion-icon>
			</button>
		</div>
		<div class="dialog__content">
			<div v-bar v-if="products && products.length > 0">
				<div>
					<ul class="products" style="height: 60vh">
						<li
							class="product is-flex is-align-items-center p-3"
							v-for="product in products"
							:key="product.id"
						>
							<a
								:href="product._routePath"
								target="_blank"
								class="product__image pr-3"
							>
								<img
									:src="
										`${process.env.VUE_APP_STRAPI_DATABASE_URL}/${product.imagine.url}`
									"
									:alt="product.titlu"
								/>
							</a>
							<a
								:href="product._routePath"
								target="_blank"
								class="product__title pr-3"
								>{{ product.titlu }}</a
							>
							<div class="product__quantity">
								<label for="quantity">Cantitate produs</label>
								<input
									type="number"
									id="quantity"
									name="quantity"
									min="1"
									v-model="product._cantitate"
									@change="updatePrice(product.id)"
								/>
							</div>
							<div class="product__price has-text-centered pr-3">
								{{ product._pret }} RON
							</div>
							<button
								class="btn btn--close"
								@click="removeProduct(product)"
								title="Șterge produs"
								aria-label="Șterge produs din listă"
							>
								<ion-icon name="trash-outline"></ion-icon>
							</button>
						</li>
					</ul>
				</div>
			</div>
			<div class="empty-list" v-else>
				Nu aveți produse în listă!
			</div>
		</div>

		<div class="dialog__footer is-flex is-justify-content-flex-end p-4">
			<button
				class="btn btn--secondary mr-2"
				v-if="products && products.length > 0"
				@click="clearList"
			>
				Golește lista
			</button>
			<button class="btn btn--primary mr-2" @click="closeModal">
				Înapoi la produse
			</button>
			<button
				class="btn btn--primary"
				@click="openModalCommandWithMiltipleProducts"
			>
				Pasul următor
			</button>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['products'],
		methods: {
			closeModal() {
				this.$emit('close');
				this.$store.commit(
					'product/SET_MODAL_PRODUCTS_LIST_STATUS',
					false
				);
			},
			removeProduct(product) {
				this.$store.commit('product/REMOVE_PRODUCT_FROM_LIST', product);
			},
			clearList() {
				this.$store.commit('product/CLEAR_LIST');
			},
			openModalCommandWithMiltipleProducts() {
				this.$store.commit(
					'product/SET_MODAL_COMMAND_WITH_MULTIPLE_PRODUCTS_STATUS',
					true
				);
			},
			updatePrice(id) {
				this.$store.commit('product/UPDATE_QUANTITY_PRICE', id);
			},
		},
	};
</script>

<style scoped lang="scss">
	.products {
		padding-right: calc(1rem + 10px);
		padding-left: 1rem;
	}
	.product {
		background-color: $white;
		border-radius: $round-corners;
		margin-bottom: 1rem;
		&:last-child {
			margin: 0;
		}
		&__image {
			display: block;
			width: 60px;
			img {
				width: 60px;
				height: auto;
			}
		}
		&__title {
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: calc(100% - (60px + 80px + 150px + 35px));
		}
		&__quantity {
			label {
				position: absolute;
				left: -9999px;
			}
			input {
				background: none;
				box-sizing: border-box;
				border: 1px solid $gray;
				border-radius: $round-corners;
				color: $primary;
				display: inline-block;
				font-size: 1rem;
				padding: 0.5rem;
				text-align: center;
				transition: border-color 0.3s ease-in-out,
					box-shadow 0.3s ease-in-out;
				width: 80px;
				&:focus {
					box-shadow: 0 0 0 3px rgba($primary, 0.25);
					border-color: $primary;
					outline: 0;
				}
			}
		}
		&__price {
			color: $primary;
			font-weight: $semibold;
			width: 150px;
		}
		.btn {
			font-size: 1.125rem;
			line-height: 38px;
			text-align: center;
			width: 35px;
			height: 35px;
			ion-icon {
				pointer-events: none;
			}
		}
		@media (max-width: 550px) {
			flex-direction: column;
			.product {
				&__image {
					width: 100px;
					padding: 0 !important;
					img {
						width: 100%;
					}
				}
				&__title {
					width: auto;
					padding: 0 !important;
					margin-bottom: 0.5rem;
				}
				&__price {
					padding: 0 !important;
					margin: 0.5rem 0;
				}
			}
		}
	}
	.empty-list {
		display: flex;
		font-weight: $semibold;
		align-items: center;
		justify-content: center;
		height: 100%;
	}
</style>
