<template>
	<section class="section">
		<div class="container has-text-centered">
			<h2 class="section__title has-text-centered">
				Bun venit!
				<p>
					Ne bucurăm că ne vizitezi. Ascultă un mesaj audio de bun
					venit din partea noastră.
				</p>
			</h2>
			<audio controls>
				<source :src="audio" />
				Browser-ul dvs. nu suportă elementul audio.
			</audio>
		</div>
	</section>
</template>

<script>
	export default {
		props: {
			audio: String,
		},
	};
</script>

<style lang="scss" scoped>
	p {
		text-transform: initial;
		font-weight: $medium;
		font-size: 1.25rem;
	}
</style>
