<template>
  <section class="section section--larger">
    <div class="container has-text-centered">
      <div class="motto is-relative">
        <span>&#8220;</span>
        <slot></slot>
        <span>&#8221;</span>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'Motto',
  };
</script>

<style scoped lang="scss">
  h2 {
    display: inline-block;
    font-size: 1.5rem;
    padding: 1rem;
    text-transform: uppercase;
    text-align: left;
    @media (max-width: 768px) {
      font-size: 1.25rem;
      text-align: center;
    }
  }
  .motto {
    display: inline-flex;
    align-items: flex-start;
  }
  span {
    color: $secondary;
    font-size: 10rem;
    line-height: 8rem;
    height: 48px;
    &:first-of-type {
      align-self: flex-start;
      transform: translateY(-1.5rem);
    }
    &:last-of-type {
      align-self: flex-end;
      transform: translateY(1.5rem);
    }
  }
</style>
