<template>
	<div id="app">
		<!-- Load Facebook SDK for JavaScript -->
		<!-- <div id="fb-root"></div> -->
		<!-- <div
			class="fb-customerchat"
			attribution="setup_tool"
			page_id="461713137349651"
			theme_color="#13cf13"
			logged_in_greeting="Bună, vă mulțumim că ne-ați contactat pe Messenger!"
			logged_out_greeting="Bună, vă mulțumim că ne-ați contactat pe Messenger!"
		></div> -->
		<div
			class="overlay"
			:class="{ 'is-open': menuIsOpen }"
			@click.self="closeMenuOnOverlay"
		></div>
		<menu-component
			@open-menu="openMenu"
			@close-menu="closeMenuOnButton"
			:is-closed="menuIsOpen"
		/>
		<template v-if="$route.path === '/'">
			<banner-carousel
				:slides="homeBannerCarouselSlides"
			></banner-carousel>
			<component-audio v-if="audio && audio.length > 0" :audio="audio" />
			<motto-component v-if="homeMotto[0]" style="background: #f7f7f7;">
				<h2 class="has-text-centered">{{ homeMotto[0].titlu }}</h2>
			</motto-component>
			<section class="section section--larger">
				<div class="container">
					<div class="columns is-multiline is-centered">
						<component-category
							v-for="category in categories"
							:key="category.id"
							:category="category"
						/>
					</div>
				</div>
			</section>
		</template>
		<contact-links />
		<transition v-if="loading" name="fade">
			<component-loading />
		</transition>
		<router-view v-else :key="$route.path" />
		<component-certificates
			:bgGrey="true"
			v-if="$route.path === '/'"
			:certificates="certificates"
		></component-certificates>
		<motto-component v-if="$route.path === '/' && homeMotto[1]">
			<h2 class="has-text-centered" v-html="homeMotto[1].titlu"></h2>
		</motto-component>
		<testimonial-carousel v-if="$route.path === '/'" />
		<template v-if="$route.path === '/'">
			<!-- <section
				class="section section--larger"
				v-if="products && products.length > 0"
			>
				<div class="container">
					<h2 class="section__title has-text-centered">
						Produse
					</h2>
					<div class="columns is-multiline">
						<div
							class=" column is-4 mb-5"
							v-for="product in products"
							:key="product.id"
						>
							<component-product
								fromPage="home"
								:product="product"
							/>
						</div>
					</div>
				</div>
			</section> -->
			<section
				class="section section--larger"
				v-if="articles && articles.length > 0"
			>
				<div class="container">
					<h2 class="section__title has-text-centered">
						Articole
					</h2>
					<div class="columns is-multiline">
						<div
							class="column is-4 mb-5"
							v-for="article in articles"
							:key="article.id"
						>
							<component-article
								fromPage="home"
								:article="article"
							></component-article>
						</div>
					</div>
				</div>
			</section>
		</template>

		<template v-if="contentOnlyOnCategoriesPage || $route.path === '/'">
			<owner-carousel />
		</template>
		<footer-component />

		<div class="product-page">
			<!-- MODAL WITH LIST OF PRODUCTS -->
			<b-modal
				v-model="modalProductsListSetterAndGetter"
				trap-focus
				:destroy-on-hide="false"
				aria-role="dialog"
				aria-modal
			>
				<template #default="props">
					<products-list
						:products="productsList"
						@close="props.close"
					></products-list>
				</template>
			</b-modal>

			<!-- COMMAND MODAL WITH MULTIPLE PRODUCTS  -->
			<b-modal
				v-model="modalWithMultipleProductsSetterAndGetter"
				trap-focus
				:destroy-on-hide="false"
				aria-role="dialog"
				aria-modal
			>
				<template #default="props">
					<component-command-modal
						@close="props.close"
						:products="productsList"
					></component-command-modal>
				</template>
			</b-modal>
		</div>
		<transition name="slide">
			<component-submited-form
				v-if="submitedForm"
				:submitedForm="submitedForm"
			/>
		</transition>
	</div>
</template>
<script>
	import MenuComponent from '@/components/TheMenu.vue';
	import FooterComponent from '@/components/TheFooter.vue';
	import BannerCarousel from '@/components/TheBannerCarousel.vue';
	import MottoComponent from '@/components/TheMotto.vue';
	import ComponentCategory from '@/components/ComponentCategory.vue';
	import ContactLinks from '@/components/TheContactLinks.vue';
	import OwnerCarousel from '@/components/TheOwnerCarousel.vue';
	import TestimonialCarousel from '@/components/TheTestimonialCarousel.vue';
	import ComponentCertificates from '@/components/ComponentCertificates.vue';
	import ProductsList from '@/components/ComponentProductsList.vue';
	import ComponentCommandModal from '@/components/ComponentCommandModal.vue';
	// import ComponentProduct from '@/components/ComponentProduct.vue';
	import ComponentArticle from '@/components/ComponentArticle.vue';
	import ComponentSubmitedForm from '@/components/ComponentSubmitedForm.vue';
	import ComponentAudio from '@/components/ComponentAudio.vue';
	import ComponentLoading from '@/components/ComponentLoading.vue';

	import { mapState, mapActions } from 'vuex';

	export default {
		title: 'Forever Diamond Team',
		components: {
			MenuComponent,
			FooterComponent,
			BannerCarousel,
			MottoComponent,
			ComponentCategory,
			ContactLinks,
			OwnerCarousel,
			TestimonialCarousel,
			ComponentCertificates,
			ProductsList,
			ComponentCommandModal,
			// ComponentProduct,
			ComponentArticle,
			ComponentSubmitedForm,
			ComponentAudio,
			ComponentLoading,
		},
		data() {
			return {
				menuIsOpen: false,
			};
		},
		created() {
			this.getGeneralData();
		},
		computed: {
			...mapState('general', [
				'homeMotto',
				'categories',
				'certificates',
				'homeBannerCarouselSlides',
				'products',
				'articles',
				'audio',
				'audioStatus',
				'submitedForm',
				'loading',
			]),
			...mapState('product', [
				'modalProductsList',
				'modalWithMultipleProducts',
				'productsList',
			]),
			contentOnlyOnCategoriesPage() {
				return (
					this.$route.path === '/aloe-vera' ||
					this.$route.path === '/igiena-personala' ||
					this.$route.path === '/sanatate' ||
					this.$route.path === '/dezvoltare-personala' ||
					this.$route.path === '/frumusete' ||
					this.$route.path === '/detoxifiere-controlul-greutatii' ||
					this.$route.path === '/nutritie-avansata-si-sport' ||
					this.$route.path === '/business-alatura-te-echipei'
				);
			},
			modalProductsListSetterAndGetter: {
				set() {
					return this.$store.commit(
						'product/SET_MODAL_PRODUCTS_LIST_STATUS',
						false
					);
				},
				get() {
					return this.modalProductsList;
				},
			},
			modalWithMultipleProductsSetterAndGetter: {
				set() {
					return this.$store.commit(
						'product/SET_MODAL_COMMAND_WITH_MULTIPLE_PRODUCTS_STATUS',
						false
					);
				},
				get() {
					return this.modalWithMultipleProducts;
				},
			},
		},
		methods: {
			...mapActions('general', ['getGeneralData']),
			openMenu(value) {
				this.menuIsOpen = value;
			},
			closeMenuOnOverlay() {
				this.menuIsOpen = false;
			},
			closeMenuOnButton(value) {
				this.menuIsOpen = value;
			},
		},
		watch: {
			$route() {
				this.menuIsOpen = false;
			},
		},
	};
</script>

<style lang="scss">
	.product-page .modal-content,
	.business-page .modal-content {
		width: 100%;
	}
	// Menu
	.overlay {
		background-color: rgba($black, 0.85);
		opacity: 0;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		transition: opacity 0.3s ease;
		pointer-events: none;
		z-index: 3;
		&.is-open {
			opacity: 1;
			pointer-events: all;
		}
	}
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s;
	}
	.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}

	/*----------  GENERAL  ----------*/
	.modal-close {
		display: none;
	}
	.dialog {
		border-radius: $round-corners;
		.vuebar {
			height: 500px;
		}
	}
	.vuebar {
		height: 250px;
		width: 100%;
	}

	.vb > .vb-dragger {
		z-index: 5;
		width: 10px;
		right: 0;
	}

	.vb > .vb-dragger > .vb-dragger-styler {
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		-webkit-transform: rotate3d(0, 0, 0, 0);
		transform: rotate3d(0, 0, 0, 0);
		-webkit-transition: background-color 100ms ease-out,
			margin 100ms ease-out, height 100ms ease-out;
		transition: background-color 100ms ease-out, margin 100ms ease-out,
			height 100ms ease-out;
		background-color: rgba($primary, 0.4);
		margin: 0;
		border-radius: $round-corners;
		height: 100%;
		display: block;
	}

	.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
		background-color: rgba($primary, 0.4);
	}

	.vb > .vb-dragger:hover > .vb-dragger-styler {
		background-color: rgba($primary, 0.8);
		margin: 0px;
		height: 100%;
	}

	.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
		background-color: rgba($primary, 0.8);
		margin: 0px;
		height: 100%;
	}

	.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
		background-color: rgba($primary, 0.8);
	}
	.section {
		&--larger {
			padding: 4rem 1.5rem 4rem 1.5rem !important;
		}
		h2.section__title {
			font-size: 1.75rem;
			margin-bottom: 4rem;
			text-transform: uppercase;
		}
	}
	.modal-content {
		overflow: initial;
		max-height: initial;
	}
	.page-aloe-vera {
		em {
			display: inline-block;
			padding: 0.5rem;
		}
	}
	/*----------  MARKDOWN  ----------*/
	.footer__bottom {
		.markdown-body {
			p,
			strong {
				color: darken($gray, 10%);
				font-size: 1rem;
			}
			a {
				color: darken($gray, 5%) !important;
				&:hover {
					color: darken($gray, 20%) !important;
				}
			}
		}
	}
	.markdown-body {
		.anchor {
			display: none !important;
		}
		h2 {
			padding: 0 !important;
			border: 0 !important;
			font-size: 1.5rem !important;
		}
		p {
			font-size: 1.125rem;
		}
		strong {
			color: $black;
		}
		a {
			color: $primary !important;
			text-decoration: none !important;
			&:hover {
				color: $secondary !important;
			}
		}
		ul,
		ol {
			padding: 0 !important;
		}
		ul li {
			list-style-type: disc !important;
		}
	}

	/*----------  HOME PAGE  ----------*/
	.swiper-pagination {
		bottom: 0 !important;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 20px;
	}
	.testimonial {
		padding-bottom: 20px;
		.swiper-container-horizontal > .swiper-pagination-bullets {
			bottom: 0;
		}
		.swiper-pagination-bullet {
			background-color: transparent;
			border: 2px solid $secondary;
			opacity: 1;
			width: 0.875rem;
			height: 0.875rem;
		}
		.swiper-pagination-bullet-active {
			background-color: $secondary;
		}
	}
	/*----------  BUSINESS PAGE  ----------*/
	.business-page {
		.banner {
			strong {
				display: block;
			}
		}
	}

	.slide-enter-active,
	.slide-leave-active {
		transition: opacity 0.5s;
	}
	.slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
		opacity: 0;
	}
</style>
