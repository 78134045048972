<template>
	<div class="dialog" style="background: white">
		<div class="dialog__header is-flex p-4">
			<button
				style="margin-left: auto"
				class="btn btn--close"
				@click="closeModal"
			>
				<ion-icon name="close-outline"></ion-icon>
			</button>
		</div>

		<div class="dialog__content" v-bar>
			<div>
				<div class="inner" style="height: 60vh">
					<!-- PRODUCTS LIST -->
					<ul v-if="products" class="products mb-4">
						<li
							class="product is-flex is-align-items-center p-2"
							v-for="product in products"
							:key="product.id"
						>
							<a
								:href="product._routePath"
								target="_blank"
								class="product__image is flex is-align-items-center is-justify-content-center"
							>
								<img
									:src="
										`${process.env.VUE_APP_STRAPI_DATABASE_URL}/${product.imagine.url}`
									"
									:alt="product.titlu"
								/>
							</a>
							<a
								:href="product._routePath"
								target="_blank"
								class="product__title px-3"
							>
								{{ product.titlu }}
							</a>
							<div class="product__price has-text-centered">
								{{ product._pret }} RON
							</div>
						</li>
					</ul>
					<!-- PRODUCT -->
					<div
						v-if="product"
						class="product product__with-quantity is-flex no-border is-align-items-center mb-4 p-2"
					>
						<a
							:href="product._routePath"
							target="_blank"
							class="product__image is-flex is-justify-content-center"
						>
							<img
								:src="
									`${process.env.VUE_APP_STRAPI_DATABASE_URL}/${product.imagine.url}`
								"
								:alt="product.titlu"
							/>
						</a>
						<a
							:href="product._routePath"
							target="_blank"
							class="product__title px-3"
						>
							{{ product.titlu }}
						</a>
						<div class="product__quantity">
							<label for="quantity">Cantitate produs</label>
							<input
								type="number"
								id="quantity"
								name="quantity"
								min="1"
								v-model="product._cantitate"
								@change="updatePrice(product.id)"
							/>
						</div>
						<div class="product__price has-text-centered">
							{{ product._pret }} RON
						</div>
					</div>
					<!-- FORM -->
					<form ref="form" class="form">
						<h3 class="mb-4">Mă intersează</h3>
						<div class="checkbox-wrapper is-flex is-flex-wrap-wrap">
							<div
								class="field is-flex is-align-items-center p-4 mb-0"
							>
								<label
									for="full-price"
									class="no-borders no-bg is-flex is-align-items-center"
								>
									<div class="checkbox is-relative mr-3">
										<input
											type="checkbox"
											id="full-price"
											v-model="fullPrice"
										/>
										<span class="checkbox__box"
											><ion-icon
												name="checkmark-outline"
											></ion-icon
										></span>
									</div>
									<p>Cu preț întreg (de catalog)</p>
								</label>
							</div>
							<div
								class="field is-flex is-align-items-center p-4 mb-0"
							>
								<label
									for="disccount-consulting"
									class="no-borders no-bg is-flex is-align-items-center"
								>
									<div class="checkbox is-relative mr-3">
										<input
											type="checkbox"
											id="disccount-consulting"
											v-model="disccountAndConsulting"
										/>
										<span class="checkbox__box"
											><ion-icon
												name="checkmark-outline"
											></ion-icon
										></span>
									</div>
									<p>
										Cu discount de „Preferred costumer” 5%
										și consultanță personalizată
									</p>
								</label>
							</div>
							<div
								class="field is-flex is-align-items-center p-4 mb-0"
							>
								<label
									for="consultant"
									class="no-borders no-bg is-flex is-align-items-center"
								>
									<div class="checkbox is-relative mr-3">
										<input
											type="checkbox"
											id="consultant"
											v-model="consultant"
										/>
										<span class="checkbox__box"
											><ion-icon
												name="checkmark-outline"
											></ion-icon
										></span>
									</div>
									<p>
										Cum pot devenii si eu consultant Forever
									</p>
								</label>
							</div>
							<div
								class="field is-flex is-align-items-center p-4 mb-0"
							>
								<label
									for="disccount"
									class="no-borders no-bg is-flex is-align-items-center"
								>
									<div class="checkbox is-relative mr-3">
										<input
											type="checkbox"
											id="disccount"
											v-model="disccount"
										/>
										<span class="checkbox__box"
											><ion-icon
												name="checkmark-outline"
											></ion-icon
										></span>
									</div>
									<p>
										Cum pot ajunge la discount de 30%
									</p>
								</label>
							</div>
						</div>
						<div class="columns is-multiline">
							<div class="column is-6">
								<div
									class="field"
									:class="{ 'field--error': $v.name.$error }"
								>
									<label for="name">Nume</label>
									<input
										type="text"
										id="name"
										name="name"
										v-model="name"
									/>
									<p
										class="error"
										v-if="
											!$v.name.required && $v.name.$dirty
										"
									>
										Câmpul este obligatoriu
									</p>
									<p
										class="error"
										v-if="!$v.name.alpha && $v.name.$dirty"
									>
										Câmpul trebuie să conțină doar caractere
									</p>
								</div>
							</div>
							<div class="column is-6">
								<div
									class="field"
									:class="{ 'field--error': $v.phone.$error }"
								>
									<label for="phone">Telefon</label>
									<input
										type="text"
										id="phone"
										name="phone"
										v-model="phone"
									/>
									<p
										class="error"
										v-if="
											!$v.phone.required &&
												$v.phone.$dirty
										"
									>
										Câmpul este obligatoriu
									</p>
									<p
										class="error"
										v-if="
											(!$v.phone.minLength ||
												!$v.phone.maxLength) &&
												$v.phone.$dirty
										"
									>
										Câmpul trebuie să conțină
										{{ $v.phone.$params.maxLength.max }}
										numere
									</p>
								</div>
							</div>
							<div class="column is-6">
								<div
									class="field"
									:class="{ 'field--error': $v.mail.$error }"
								>
									<label for="mail">Mail</label>
									<input
										type="text"
										id="mail"
										v-model="mail"
									/>
									<p
										class="error"
										v-if="
											!$v.mail.required && $v.mail.$dirty
										"
									>
										Câmpul este obligatoriu
									</p>
									<p
										class="error"
										v-if="!$v.mail.email && $v.mail.$dirty"
									>
										Adresa de mail trebuie să fie validă
									</p>
								</div>
							</div>
							<div class="column is-6">
								<div
									class="field"
									:class="{ 'field--error': $v.place.$error }"
								>
									<label for="place">Localiate</label>
									<input
										type="text"
										v-model="place"
										id="place"
									/>
									<p
										class="error"
										v-if="
											!$v.place.required &&
												$v.place.$dirty
										"
									>
										Câmpul este obligatoriu
									</p>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="dialog__footer is-flex is-justify-content-flex-end p-4">
			<button @click="closeModal" class="btn btn--secondary mr-2">
				Închide
			</button>
			<button
				class="btn btn--secondary mr-2"
				title="Înapoi la produse"
				aria-label="Înapoi la produse"
				@click="$router.go(-1)"
			>
				Înapoi la produse
			</button>
			<button
				:disabled="$v.$anyError"
				@click.prevent="submit"
				class="btn btn--primary"
			>
				Trimite
			</button>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
	import {
		required,
		minLength,
		maxLength,
		numeric,
		email,
	} from 'vuelidate/lib/validators';
	import { helpers } from 'vuelidate/lib/validators';
	const alpha = helpers.regex('alpha', /^([a-zA-Z]+\s)*[a-zA-Z]+$/);

	export default {
		props: ['product', 'products', 'typeModal'],
		validations: {
			name: {
				required,
				alpha,
			},
			phone: {
				required,
				numeric,
				minLength: minLength(10),
				maxLength: maxLength(10),
			},
			mail: {
				required,
				email,
			},
			place: {
				required,
			},
		},
		data() {
			return {
				fullPrice: null,
				disccountAndConsulting: null,
				disccount: null,
				consultant: null,
				name: '',
				phone: null,
				mail: '',
				place: '',
			};
		},
		methods: {
			updatePrice(id) {
				this.$store.commit('product/UPDATE_QUANTITY_PRICE', {
					id,
					type: 'product',
				});
			},
			closeModal() {
				if (this.product) {
					this.$store.commit(
						'product/SET_MODAL_COMMAND_WITH_SINGLE_PRODUCT_STATUS',
						{ product: null, status: false }
					);
				} else {
					this.$store.commit(
						'product/SET_MODAL_COMMAND_WITH_MULTIPLE_PRODUCTS_STATUS',
						false
					);
				}
				this.fullPrice = null;
				this.disccountAndConsulting = null;
				this.disccount = null;
				this.consultant = null;
				this.name = '';
				this.mail = '';
				this.phone = null;
				this.place = '';
				this.$v.$reset();
				this.$emit('close');
			},
			submit() {
				this.$v.$touch();
				if (this.$v.$invalid) {
					return;
				} else {
					let products = [];
					if (this.products) {
						products = this.products.map((product) => {
							return {
								titlu: product.titlu,
								imagine: product.imagine.id,
								cantitate: product._cantitate,
								pret: product._pret,
							};
						});
					} else if (this.product) {
						products.push({
							titlu: this.product.titlu,
							imagine: this.product.imagine.id,
							cantitate: this.product._cantitate,
							pret: this.product._pret,
						});
					}
					axios
						.post(
							`${process.env.VUE_APP_STRAPI_DATABASE_URL}/orders`,
							{
								nume: this.name,
								telefon: this.phone,
								mail: this.mail,
								localitate: this.place,
								cu_pret_intreg_de_catalog: this.fullPrice,
								cu_discount_de_preferred_customer_5_si_consultanta_personalizata: this
									.disccountAndConsulting,
								cum_pot_ajunge_la_discount_de_30: this
									.disccount,
								cum_pot_deveni_si_eu_consultant_forever: this
									.consultant,
								produse: products,
							}
						)
						.then((data) => {
							axios.post(
								`${process.env.VUE_APP_STRAPI_DATABASE_URL}/emailo`,
								{
									mail: data.data.mail,
								}
							);
							axios.post(
								`${process.env.VUE_APP_STRAPI_DATABASE_URL}/emailod`,
								{ ...data }
							);
							this.$store.commit(
								'general/SET_FORM_MSG_AFTER_SUBMITED',
								{
									status: 'submited',
									msg:
										'Mulțumim pentru interes. Am preluat comanda ta și te vom contacta în cel mai scurt timp.',
								}
							);
							this.closeModal();
							this.$store.commit(
								'product/SET_MODAL_PRODUCTS_LIST_STATUS',
								false
							);
							this.$store.commit('product/CLEAR_LIST');
						})
						.catch(() => {
							this.$store.commit(
								'general/SET_FORM_MSG_AFTER_SUBMITED',
								{
									status: 'error',
									msg:
										'Am întâmpinat o eroare. Comanda ta nu a fost trimisă.',
								}
							);
							this.closeModal();
							this.$store.commit(
								'product/SET_MODAL_COMMAND_WITH_MULTIPLE_PRODUCTS_STATUS',
								false
							);
						});
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.product {
		background-color: $white;
		border-radius: $round-corners;
		margin-bottom: 0.5rem;
		&:last-child {
			margin: 0;
		}
		&__image {
			text-align: center;
			width: 60px;
			img {
				width: 60px;
				height: auto;
			}
		}
		&__title {
			font-size: 1rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: calc(100% - (60px + 150px));
		}
		&__quantity {
			label {
				position: absolute;
				left: -9999px;
			}
			input {
				background: none;
				box-sizing: border-box;
				border: 1px solid $gray;
				border-radius: $round-corners;
				color: $primary;
				display: inline-block;
				font-size: 1rem;
				padding: 0.5rem;
				text-align: center;
				transition: border-color 0.3s ease-in-out,
					box-shadow 0.3s ease-in-out;
				width: 80px;
				&:focus {
					box-shadow: 0 0 0 3px rgba($primary, 0.25);
					border-color: $primary;
					outline: 0;
				}
			}
		}
		&__price {
			color: $primary;
			font-weight: $semibold;
			width: 150px;
		}
		&__with-quantity {
			.product__title {
				width: calc(100% - (60px + 80px + 150px));
			}
		}
		@media (max-width: 550px) {
			flex-direction: column;
			.product {
				&__image {
					width: 100px;
					padding: 0 !important;
					img {
						width: 100%;
					}
				}
				&__title {
					width: auto;
					padding: 0 !important;
					margin-bottom: 0.5rem;
				}
				&__price {
					padding: 0 !important;
					margin: 0.5rem 0;
				}
			}
		}
	}
</style>
