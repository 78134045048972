import axios from 'axios';

const state = {
  article: {},
  articles: [],
};

const mutations = {
  SET_ARTICLE(state, payload) {
    state.article = payload;
  },
  SET_ARTICLES(state, payload) {
    state.articles = payload;
  },
};

const actions = {
  async getArticle({ commit }, id) {
    const response = await axios.get(`${process.env.VUE_APP_STRAPI_DATABASE_URL}/articles/${id}`);
    const article = await response.data;
    commit('SET_ARTICLE', article);
  },
  async getArticles({ commit }) {
    const response = await axios.get(`${process.env.VUE_APP_STRAPI_DATABASE_URL}/articles`);
    const articles = await response.data;
    commit('SET_ARTICLES', articles);
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
