<template>
	<section class="section section--larger">
		<div class="container is-fluid">
			<swiper class="swiper" :options="swiperOption">
				<swiper-slide
					v-for="slide in homeAboutUsSlides"
					:key="slide.id"
				>
					<div class="content is-relative mb-0">
						<img
							:src="`${databaseLink}/${slide.imagine[0].url}`"
							:alt="slide.nume"
						/>
					</div>
				</swiper-slide>
				<div class="swiper-button-prev" slot="button-prev"></div>
				<div class="swiper-button-next" slot="button-next"></div>
			</swiper>
		</div>
	</section>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		data() {
			return {
				swiperOption: {
					slidesPerView: 1,
					centeredSlides: true,
					spaceBetween: 30,
					grabCursor: true,
					loop: true,
					autoplay: {
						delay: 3000,
					},
					speed: 300,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					breakpoints: {
						576: {
							slidesPerView: 2,
						},
						1024: {
							slidesPerView: 3,
						},
						1200: {
							slidesPerView: 4,
						},
					},
				},
			};
		},
		computed: {
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			},
			...mapState('general', ['homeAboutUsSlides']),
		},
	};
</script>

<style scoped lang="scss">
	.content {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		padding-top: 56.25%;
		overflow: hidden;
		img {
			border-radius: $round-corners;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: auto;
		}
	}
	.swiper-container {
		overflow: visible;
	}
	.swiper-button-prev,
	.swiper-button-next {
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
		background: $white;
		border-radius: 50%;
		opacity: 0.9;
		transition: opacity 0.3s ease;
		height: 50px;
		width: 50px;
		&:hover {
			opacity: 1;
		}
		&::after {
			color: $secondary;
			opacity: 1;
			font-size: 1.5rem;
			font-weight: $bold;
		}
	}
	.swiper-button-prev {
		left: -25px;
	}
	.swiper-button-next {
		right: -25px;
	}
</style>
